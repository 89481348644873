@font-face {
  font-family: "Elza";
  src: url("../public/fonts/Elza/ElzaTrial-Medium.otf") format("opentype");
}

@font-face {
  font-family: "Elza-Regular";
  src: url("../public/fonts/Elza/ElzaTrial-Regular.otf") format("opentype");
}

@font-face {
  font-family: "Elza-Semibold";
  src: url("../public/fonts/Elza/ElzaTrial-Semibold.otf") format("opentype");
}

@font-face {
  font-family: "Elza-Bold";
  src: url("../public/fonts/Elza/ElzaTrial-Bold.otf") format("opentype");
}

@font-face {
  font-family: "Gallient";
  src: url("../public/fonts/Gallient/Gallient.ttf") format("opentype");
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: "Elza", "Helvetica", sans-serif;
  color: #141414;
}
